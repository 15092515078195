import React, { useState } from 'react';
import { useSetState } from 'react-use';
import {
  Box,
  HStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Icon,
  Select,
  Input,
  Checkbox,
  VStack,
  Stack,
  Button,
  Text,
  SimpleGrid,
  useDisclosure,
  Tag,
  useToast,
  FormHelperText,
} from '@chakra-ui/react';
import {
  MdLocationOn,
  MdOutlineFlight,
  MdAccessTimeFilled,
  MdDirectionsCarFilled,
  MdPerson,
  MdInfo,
  MdLuggage,
  MdClear,
} from 'react-icons/md';
import { FaBaby } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import TimePicker from 'react-time-picker';
import axios from 'axios';
import { counties } from './counties';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';

const FormBlock = ({ icon, children }) => {
  return (
    <>
      <HStack
        mt={5}
        boxShadow="0 0 5px lightgray"
        p={5}
        align="center"
        w={{ base: '100%', md: 800 }}
      >
        <Icon w={10} h={10} as={icon} />
        {children}
      </HStack>
    </>
  );
};

const FormContent = ({ title, children }) => {
  return (
    <>
      <Stack direction={{ base: 'column', md: 'row' }} align={{ base: 'stretch', md: 'center' }}>
        <Tag size="lg">{title}</Tag>
        <Text>{children}</Text>
      </Stack>
    </>
  );
};

const DropOff = () => {
  const [state, setState] = useSetState({
    isOpenContactCS: false,
    isOpenComplaint: false,
    contactTitle: '',
    contactContent: '',
    complaintTitle: '',
    complaintContent: '',
  });
  const [tabIndex, setTabIndex] = useState(1);
  const [arrive_date, setArriveDate] = useState(new Date());
  const [arrive_time, setArriveTime] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();

  // 選取縣市變換區域
  const [selectedCounty, setSelectedCounty] = useState('');
  const [districts, setDistricts] = useState([]);

  const handleCountyChange = (e) => {
    const selectedCounty = e.target.value;
    const selected = counties.find((county) => county.county === selectedCounty);
    const selectedDistricts = selected ? selected.districts : [];
    setBookInfo({ ...bookInfo, county: selectedCounty, district: '' });
    setSelectedCounty(selectedCounty);
    setDistricts(selectedDistricts);
  };

  // TimePicker clear event
  const handleClearTime = () => {
    setArriveTime(null);
  };

  // 計算總金額
  const [bookInfo, setBookInfo] = useState({
    type_id: 0,
    county: '',
    district: '',
    address: '',
    airport_code: 0,
    flight_no: '',
    arrive_date: '',
    arrive_time: '',
    car_type_id: 0,
    welcome_sign: '',
    safety_seat: [],
  });

  const [totalPrice, setTotalPrice] = useState('0');

  const API_URL = process.env.REACT_APP_API_URL;

  const calculateTotalPrice = () => {
    axios({
      method: 'post',
      url: `${API_URL}/api/order/get-price`,
      data: bookInfo,
    })
      .then((res) => {
        console.log(res.data);
        setTotalPrice(res.data.data.total_price);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // 將 bookInfo 資料傳入 reservation
  const getReserveInfo = () => {
    setReservation({
      ...reservation,
      type_id: bookInfo.type_id,
      county: bookInfo.county,
      district: bookInfo.district,
      address: bookInfo.address,
      airport_code: bookInfo.airport_code,
      flight_no: bookInfo.flight_no,
      arrive_date: bookInfo.arrive_date,
      arrive_time: bookInfo.arrive_time,
      car_type_id: bookInfo.car_type_id,
      welcome_sign: bookInfo.welcome_sign,
      safety_seat: bookInfo.safety_seat,
      passengers: bookInfo.passengers,
      luggage: bookInfo.luggage,
      total_price: totalPrice,
    });
  };

  // 送出預約
  const toast = useToast();

  const [reservation, setReservation] = useState({
    type_id: bookInfo.type_id,
    county: bookInfo.county,
    district: bookInfo.district,
    address: bookInfo.address,
    airport_code: bookInfo.airport_code,
    flight_no: bookInfo.flight_no,
    arrive_date: bookInfo.arrive_date,
    arrive_time: bookInfo.arrive_time,
    car_type_id: bookInfo.car_type_id,
    passengers: 0,
    luggage: 0,
    welcome_sign: bookInfo.welcome_sign,
    safety_seat: bookInfo.safety_seat,
    name: '',
    mobile: '',
    email: '',
    total_price: totalPrice,
  });

  const reserveTaxi = () => {
    axios({
      method: 'post',
      url: `${API_URL}/api/order`,
      data: reservation,
    })
      .then((res) => {
        console.log(res.data);
        toast({
          title: '預約成功！',
          description: '請至信箱查看預約資訊。',
          status: 'success',
          duration: 9000,
        });
      })
      .catch((err) => {
        console.log(err);
        toast({
          title: '預約失敗',
          description: '請檢查所有欄位都已填寫，或洽客服人員。',
          status: 'error',
          duration: 9000,
        });
      });
  };

  // 必填欄位
  const isError =
    bookInfo.county === '' ||
    bookInfo.district === '' ||
    bookInfo.address === '' ||
    bookInfo.arrive_date === '' ||
    bookInfo.arrive_time === null ||
    bookInfo.car_type_id === 0 ||
    bookInfo.passengers === 0 ||
    reservation.name === '' ||
    reservation.mobile === '';

  return (
    <>
      <Box>
        <VStack align="center" px={5}>
          <FormBlock icon={MdLocationOn}>
            <FormControl isRequired>
              <FormLabel>請選擇上車地點</FormLabel>

              <HStack>
                <Select
                  w={{ base: '100%', md: 300 }}
                  placeholder="縣市"
                  onChange={handleCountyChange}
                  value={bookInfo.county}
                >
                  {counties.map((county, i) => (
                    <option key={i}>{county.county}</option>
                  ))}
                </Select>
                <Select
                  w={{ base: '100%', md: 300 }}
                  placeholder="區域"
                  value={bookInfo.district}
                  onChange={(e) => setBookInfo({ ...bookInfo, district: e.target.value })}
                >
                  {districts.map((district, i) => (
                    <option key={i}>{district}</option>
                  ))}
                </Select>
              </HStack>
              <Input
                mt={2}
                w={{ base: '100%', md: 610 }}
                placeholder="詳細地址"
                value={bookInfo.address}
                onChange={(e) => {
                  setBookInfo({ ...bookInfo, address: e.target.value });
                }}
              />
            </FormControl>
          </FormBlock>

          <FormBlock icon={MdOutlineFlight}>
            <Stack direction={{ base: 'column', md: 'row' }}>
              <FormControl isRequired>
                <FormLabel>請選擇抵達機場</FormLabel>
                <Select
                  w={{ base: '100%', md: 300 }}
                  placeholder="機場"
                  value={bookInfo.airport_code}
                  onChange={(e) => {
                    setBookInfo({ ...bookInfo, airport_code: parseInt(e.target.value) });
                  }}
                >
                  {[
                    // { id: 101, name: '台北松山國際機場' },
                    { id: 202, name: '桃園國際機場第一航廈' },
                    { id: 203, name: '桃園國際機場第二航廈' },
                    // { id: 301, name: '台中國際機場' },
                    // { id: 401, name: '高雄小港國際機場' },
                  ].map((airport, i) => (
                    <option key={i} value={airport.id}>
                      {airport.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <FormControl>
                <FormLabel>航班編號</FormLabel>
                <Input
                  w="100%"
                  placeholder="請輸入航班編號"
                  type="text"
                  value={bookInfo.flight_no}
                  onChange={(e) => {
                    setBookInfo({
                      ...bookInfo,
                      flight_no: e.target.value,
                    });
                  }}
                />
              </FormControl>
            </Stack>
          </FormBlock>
          <FormBlock icon={MdAccessTimeFilled}>
            <FormControl isRequired>
              <FormLabel>請選擇日期/時間</FormLabel>
              <Stack direction={{ base: 'column', md: 'row' }}>
                <Box border="1px solid black" px={3} py="1px">
                  <DatePicker
                    value={bookInfo.arrive_date}
                    selected={arrive_date}
                    onChange={(date) => {
                      setArriveDate(date);
                      setBookInfo({
                        ...bookInfo,
                        arrive_date: format(date, 'yyyy-MM-dd'),
                      });
                    }}
                  />
                </Box>
                <TimePicker
                  disableClock
                  onChange={(time) => {
                    setArriveTime(time);
                    setBookInfo({ ...bookInfo, arrive_time: time });
                  }}
                  value={arrive_time}
                  clearIcon={<MdClear onClick={handleClearTime} />}
                />
              </Stack>
            </FormControl>
          </FormBlock>
          <FormBlock icon={MdDirectionsCarFilled}>
            <FormControl isRequired>
              <FormLabel>請選擇乘坐車種</FormLabel>
              <Select
                w={{ base: '100%', md: 300 }}
                placeholder="車種"
                value={bookInfo.car_type_id}
                onChange={(e) => {
                  const carTypeId = parseInt(e.target.value);
                  setBookInfo({
                    ...bookInfo,
                    car_type_id: carTypeId,
                  });
                }}
              >
                {[
                  { id: 1, text: '五人座(經濟型)' },
                  { id: 3, text: '五人座(XL型)' },
                  { id: 2, text: '九人座(聚樂型)' },
                ].map((taxiType, i) => (
                  <option key={i} value={taxiType.id}>
                    {taxiType.text}
                  </option>
                ))}
              </Select>
            </FormControl>
          </FormBlock>
          <FormBlock icon={MdPerson}>
            <FormControl isRequired>
              <FormLabel>請選擇乘坐人數</FormLabel>
              <Select
                w={{ base: '100%', md: 300 }}
                placeholder="人數"
                value={bookInfo.passengers}
                onChange={(e) => {
                  setBookInfo({ ...bookInfo, passengers: parseInt(e.target.value) });
                }}
              >
                {(bookInfo.car_type_id === 1
                  ? [1, 2, 3, 4]
                  : bookInfo.car_type_id === 3
                  ? [1, 2, 3, 4]
                  : bookInfo.car_type_id === 2
                  ? [1, 2, 3, 4, 5, 6, 7, 8]
                  : []
                ).map((passenger, i) => (
                  <option key={i}>{passenger}</option>
                ))}
              </Select>
            </FormControl>
          </FormBlock>
          <FormBlock icon={MdLuggage}>
            <FormControl>
              <FormLabel>請選擇行李數量</FormLabel>
              <Select
                w={{ base: '100%', md: 300 }}
                placeholder="行李數量"
                value={bookInfo.luggage}
                onChange={(e) => {
                  setBookInfo({ ...bookInfo, luggage: parseInt(e.target.value) });
                }}
              >
                {(bookInfo.car_type_id === 1
                  ? [1, 2]
                  : bookInfo.car_type_id === 3
                  ? [1, 2, 3, 4]
                  : bookInfo.car_type_id === 2
                  ? [1, 2, 3, 4, 5, 6, 7, 8]
                  : []
                ).map((luggage, i) => (
                  <option key={i}>{luggage}</option>
                ))}
              </Select>
            </FormControl>
          </FormBlock>
          <FormBlock icon={FaBaby}>
            <FormControl>
              <FormLabel>請勾選加購項目</FormLabel>
              <VStack align="start">
                <Checkbox
                  onChange={(e) => {
                    const isChecked = e.target.checked;
                    setBookInfo({ ...bookInfo, welcome_sign: isChecked ? '接機舉牌' : '' });
                  }}
                >
                  舉牌服務 $300
                </Checkbox>
                <Checkbox
                  onChange={(e) => {
                    const isChecked = e.target.checked;
                    setBookInfo({ ...bookInfo, safety_seat: isChecked ? [2] : [] });
                  }}
                >
                  兒童安全座椅 $200
                </Checkbox>
              </VStack>
            </FormControl>
          </FormBlock>
          <FormBlock icon={MdInfo}>
            <Stack direction={{ base: 'column', md: 'row' }}>
              <FormControl isRequired>
                <FormLabel>姓名</FormLabel>
                <Input
                  w="100%"
                  placeholder="請輸入姓名"
                  type="text"
                  value={reservation.name}
                  onChange={(e) => {
                    setReservation({ ...reservation, name: e.target.value });
                  }}
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel>手機號碼</FormLabel>
                <Input
                  w="100%"
                  placeholder="請輸入手機號碼"
                  type="number"
                  value={reservation.mobile}
                  onChange={(e) => {
                    setReservation({ ...reservation, mobile: e.target.value });
                  }}
                />
              </FormControl>
              <FormControl>
                <FormLabel>電子信箱</FormLabel>
                <Input
                  w="100%"
                  placeholder="請輸入電子信箱"
                  type="email"
                  value={reservation.email}
                  onChange={(e) => {
                    setReservation({ ...reservation, email: e.target.value });
                  }}
                />
                <FormHelperText letterSpacing="wider">*預約資訊會發送至此電子信箱</FormHelperText>
              </FormControl>
            </Stack>
          </FormBlock>

          {isError && (
            <Text fontSize="0.9rem" fontWeight={500} color="red.600" letterSpacing="wide">
              *必填欄位，請確認皆已填寫
            </Text>
          )}

          <HStack py={3}>
            <Button
              onClick={() => {
                calculateTotalPrice();
              }}
            >
              計算總金額
            </Button>
            <Button
              onClick={() => {
                onOpen();
                getReserveInfo();
              }}
              isDisabled={isError}
            >
              下一步
            </Button>
          </HStack>
          <Box p={16} />
        </VStack>
        <Modal isCentered size="full" isOpen={isOpen} onClose={onClose} scrollBehavior="inside">
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>送機</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <SimpleGrid columns={[1, null, 2]} spacing={5}>
                <FormContent title="上車地點">
                  {bookInfo.county}
                  {bookInfo.district}
                  {bookInfo.address}
                </FormContent>
                <FormContent title="抵達">
                  {
                    [
                      // { id: 101, name: '台北松山國際機場' },
                      { id: 202, name: '桃園國際機場第一航廈' },
                      { id: 203, name: '桃園國際機場第二航廈' },
                      // { id: 301, name: '台中國際機場' },
                      // { id: 401, name: '高雄小港國際機場' },
                    ].find((airport) => airport.id === bookInfo.airport_code)?.name
                  }
                </FormContent>

                <FormContent title="航班編號">{bookInfo.flight_no}</FormContent>

                <FormContent title="日期時間">
                  {bookInfo.arrive_date} {bookInfo.arrive_time}
                </FormContent>

                <FormContent title="乘坐車種">
                  {
                    [
                      { id: 1, text: '五人座(經濟型)' },
                      { id: 3, text: '五人座(XL型)' },
                      { id: 2, text: '九人座(聚樂型)' },
                    ].find((taxiType) => taxiType.id === bookInfo.car_type_id)?.text
                  }
                </FormContent>

                <FormContent title="乘坐人數">{bookInfo.passengers}人</FormContent>

                <FormContent title="行李數量">{bookInfo.luggage}件</FormContent>

                <FormContent title="加購項目">
                  {bookInfo.welcome_sign === '' && bookInfo.safety_seat.length < 1 ? '無' : ''}
                  {bookInfo.welcome_sign} {bookInfo.safety_seat.length > 0 && '兒童安全座椅'}
                </FormContent>

                <FormContent title="姓名">{reservation.name}</FormContent>

                <FormContent title="手機號碼">{reservation.mobile}</FormContent>

                <FormContent title="電子信箱">{reservation.email}</FormContent>

                <Stack
                  direction={{ base: 'column', md: 'row' }}
                  align={{ base: 'stretch', md: 'center' }}
                >
                  <Tag colorScheme="blue" size="lg">
                    總金額
                  </Tag>
                  <Text>{totalPrice}</Text>
                </Stack>
              </SimpleGrid>
            </ModalBody>

            <ModalFooter>
              <Button
                bg="black"
                color="white"
                rounded="none"
                mr={2}
                onClick={() => {
                  reserveTaxi();
                }}
              >
                預約
              </Button>
              <Button rounded="none" onClick={onClose}>
                修改
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        <Box pos="fixed" bottom="0" left="0" w="100%" boxShadow="0 -2px 5px lightgray">
          <HStack
            bg="#ffd700"
            px={5}
            py={2}
            justify="end"
            color="white"
            fontSize="1.2rem"
            fontWeight={500}
            letterSpacing="wider"
          >
            <Text value={reservation.total_price}>總金額 NT${totalPrice}</Text>
          </HStack>
          <SimpleGrid pt="2px" columns={[2]} spacing="2px" bg="white">
            {/* <Button
              onClick={() => {
                window.open('/', 'blank');
              }}
              rounded="none"
              bg="black"
              color="white"
            >
              乘客須知
            </Button>
            <Button
              onClick={() => {
                window.open('/', 'blank');
              }}
              rounded="none"
              bg="black"
              color="white"
            >
              公司資訊
            </Button> */}
            <Button
              onClick={() => {
                setState({
                  isOpenContactCS: true,
                  contactTitle: '聯絡客服',
                  contactContent: '電話：09xx-xxx-xxx',
                });
              }}
              rounded="none"
              bg="black"
              color="white"
            >
              聯絡客服
            </Button>
            <Button
              onClick={() => {
                setState({
                  isOpenComplaint: true,
                  complaintTitle: '投訴專線',
                  complaintContent: '電話：09xx-xxx-xxx',
                });
              }}
              rounded="none"
              bg="black"
              color="white"
            >
              我要投訴
            </Button>
          </SimpleGrid>
        </Box>
        <Modal
          isCentered
          size="md"
          isOpen={state.isOpenContactCS}
          onClose={() => {
            setState({
              isOpenContactCS: false,
            });
          }}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{state.contactTitle}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text fontSize="1rem" fontWeight={500}>
                {state.contactContent}
              </Text>
            </ModalBody>

            <ModalFooter>
              <Button
                rounded="none"
                color="black"
                bg="gray.200"
                _hover={{ bg: 'gray.100' }}
                onClick={() => {
                  setState({
                    isOpenContactCS: false,
                  });
                }}
              >
                關閉
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        <Modal
          isCentered
          size="md"
          isOpen={state.isOpenComplaint}
          onClose={() => {
            setState({
              isOpenComplaint: false,
            });
          }}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{state.complaintTitle}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text fontSize="1rem" fontWeight={500}>
                {state.complaintContent}
              </Text>
            </ModalBody>

            <ModalFooter>
              <Button
                rounded="none"
                color="black"
                bg="gray.200"
                _hover={{ bg: 'gray.100' }}
                onClick={() => {
                  setState({
                    isOpenComplaint: false,
                  });
                }}
              >
                關閉
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>
    </>
  );
};

export default DropOff;
