import React from 'react';
import { Center, Box, SimpleGrid, Image, Button } from '@chakra-ui/react';
import { colors } from '../colors';

const TouristSpot = () => {
  return (
    <>
      <Center pt={5} px={{ base: 5, md: 10 }}>
        <SimpleGrid columns={[1, null, 2]} spacing={5}>
          <Image src="/images/tourist-attractions.jpg" />
          <Box>

            <SimpleGrid pt={5} columns={[2, null, 2]} spacing={3}>
              {[
                { text: '台灣 北部景點', href: 'https://www.taiwan.net.tw/m1.aspx?sNo=0000501' },
                { text: '台灣 中部景點', href: 'https://www.taiwan.net.tw/m1.aspx?sNo=0000502' },
                { text: '台灣 南部景點', href: 'https://www.taiwan.net.tw/m1.aspx?sNo=0000503' },
                { text: '台灣 東部景點', href: 'https://www.taiwan.net.tw/m1.aspx?sNo=0000504' },
              ].map((button, i) => (
                <a key={i} href={button.href} target="_blank" rel="noreferrer">
                  <Button
                    w="100%"
                    size="lg"
                    bg="#edb315"
                    color="white"
                    rounded="none"
                    _hover={{ bg: '#f7d985' }}
                    cursor="pointer"
                  >
                    {button.text}
                  </Button>
                </a>
              ))}
            </SimpleGrid>
            {/* <Box
              w="100%"
              mt={3}
              p={3}
              bg={colors.theme}
              color="white"
              fontSize="1.2rem"
              fontWeight={500}
              textAlign="center"
            >
              景點介紹
            </Box> */}
          </Box>
        </SimpleGrid>
      </Center>
      <Box p={5} />
    </>
  );
};

export default TouristSpot;
