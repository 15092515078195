import React from 'react';
import {
  Center,
  Box,
  Text,
  SimpleGrid,
  Image,
  Divider,
  List,
  ListIcon,
  ListItem,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react';
import { TriangleUpIcon } from '@chakra-ui/icons';
import { colors } from '../colors';

const rentalContents = [
  {
    title: '優惠區域： 基隆市、台北市、新北市、桃園市、新竹市、 宜蘭市、花蓮（花蓮市區以北）',
    content: '包車時間：十小時/一日\n限上、下車地區：（本區域）',
    price: '《 五人座 》\n包車價格：4999元\n乘車人數：4位\n\n《 九人座 》\n包車價格：6999元\n乘車人數：8位',
  },
  {
    title: '區域價格：苗栗、台中、彰化、南投',
    content: '包車時間：十小時/一日\n限上、下車地區：（北北基、桃竹苗、中彰投）',
    price: '《 五人座 》\n包車價格：5999元\n乘車人數：4位\n\n《 九人座 》\n包車價格：7999元\n乘車人數：8位',
  },
  {
    title: '區域價格：雲林、嘉義、台南、高雄',
    content: '包車時間：十小時/一日',
    price: '《 五人座 》\n包車價格：7500元\n乘車人數：4位\n\n《 九人座 》\n包車價格：8800元\n乘車人數：8位',
  },
  {
    title: '區域價格：屏東（限單程）',
    content: '包車時間：十小時/一日',
    price: '《 五人座 》\n包車價格：9500元\n乘車人數：4位\n\n《 九人座 》\n包車價格：11800元\n乘車人數：8位',
  },
  {
    title: '區域價格：花蓮（市區以南）',
    content: '包車時間：十小時/一日\n上、下車地區：（限北北基、桃、宜）',
    price: '《 五人座 》\n包車價格：7500元\n乘車人數：4位\n\n《 九人座 》\n包車價格：9500元\n乘車人數：8位',
  },
  {
    title: '區域價格：台東（限單程）',
    content: '包車時間：十二小時/一日',
    price: '《 五人座 》\n包車價格：12000元\n乘車人數：4位\n\n《 九人座 》\n包車價格：14000元\n乘車人數：8位',
  },
];

const HourlyRental = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Center pt={5} px={{ base: 5, md: 10 }}>
        <SimpleGrid columns={[1, null, 2]} spacing={5}>
          <Box>
            <Image src="/images/tourist-attractions.jpg" />
            <Box mt={5} p={5} bg="gray.100">
              <List spacing={2}>
                {[
                  '車資含油錢，過路費，司機食宿，每一位乘客險500萬',
                  '如路程前往深山區：每趟斟酌 + 500～2000元',
                  '包車逾時，每小時+500',
                ].map((list, i) => (
                  <ListItem key={i}>
                    <ListIcon as={TriangleUpIcon} color={colors.theme} />
                    {list}
                  </ListItem>
                ))}
              </List>
            </Box>
            <Button
              onClick={onOpen}
              mt={3}
              w="100%"
              size="lg"
              bg={colors.theme}
              color="white"
              rounded="none"
            >
              立即詢問
            </Button>
          </Box>
          <Box>
            <Text fontSize="1.5rem" fontWeight={600}>
              全台包車 區域價
            </Text>
            {rentalContents.map((rental, i) => (
              <Box key={i}>
                <Text pt={5} pb={1} fontSize="1.2rem" fontWeight={500} lineHeight="26px">
                  {rental.title}
                </Text>
                <Text fontSize="1rem" lineHeight="26px" whiteSpace="pre-line">
                  {rental.content}
                </Text>
                <Text fontSize="1rem" lineHeight="26px" whiteSpace="pre-line">
                  {rental.price}
                </Text>
                <Divider pt={2} mb={3} />
              </Box>
            ))}
          </Box>
        </SimpleGrid>
      </Center>
      <Box p={5} />

      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>詢問專線</ModalHeader>
          <ModalCloseButton />
          <ModalBody fontSize="1.2rem">電話：0909-588-123</ModalBody>

          <ModalFooter />
        </ModalContent>
      </Modal>
    </>
  );
};

export default HourlyRental;
