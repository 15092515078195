import React, { useState } from 'react';
import { Box, Button, SimpleGrid } from '@chakra-ui/react';
import { counties } from './counties';
import PickUp from './pickUp';
import DropOff from './dropOff';
import HourlyRental from './hourlyRental';
import TouristSpot from './touristSpot';

const Reservation = () => {
  const [tabIndex, setTabIndex] = useState(0);

  // 選取縣市變換區域
  const [selectedCounty, setSelectedCounty] = useState('');
  const [districts, setDistricts] = useState([]);

  const handleCountyChange = (e) => {
    const selectedCounty = e.target.value;
    const selected = counties.find((county) => county.county === selectedCounty);
    const selectedDistricts = selected ? selected.districts : [];
    setBookInfo({ ...bookInfo, county: selectedCounty, district: '' });
    setSelectedCounty(selectedCounty);
    setDistricts(selectedDistricts);
  };

  // 計算總金額
  const [bookInfo, setBookInfo] = useState({
    type_id: 0,
    county: '',
    district: '',
    address: '',
    airport_code: 0,
    flight_no: '',
    arrive_date: '',
    arrive_time: '',
    car_type_id: 1,
    welcome_sign: '',
    safety_seat: [],
  });

  return (
    <>
      <Box p={10} />
      <SimpleGrid columns={[2, null, 3]} spacing={{ base: 2, md: 3 }} px={5}>
        <Button
          w="100%"
          rounded="none"
          bg={tabIndex === 0 ? 'gray.300' : 'red.300'}
          fontSize={{ base: '1rem', md: '1.2rem' }}
          color="white"
          letterSpacing="wider"
          size="lg"
          onClick={() => {
            setTabIndex(0);
            setBookInfo({ ...bookInfo, type_id: 0 });
          }}
        >
          接機
        </Button>
        <Button
          w="100%"
          rounded="none"
          bg={tabIndex === 1 ? 'gray.300' : '#97bfaf'}
          fontSize={{ base: '1rem', md: '1.2rem' }}
          color="white"
          letterSpacing="wider"
          size="lg"
          onClick={() => {
            setTabIndex(1);
            setBookInfo({ ...bookInfo, type_id: 1 });
          }}
        >
          送機
        </Button>
        {[
          // {
          //   onClick: () => {
          //     setTabIndex('hourly-rental');
          //   },
          //   state: 'hourly-rental',
          //   text: '鐘點租車旅遊',
          //   bgColor: 'orange.300',
          // },
          {
            onClick: () => {
              setTabIndex('tourist-spot');
            },
            state: 'tourist-spot',
            text: '觀光景點介紹服務',
            bgColor: 'blue.200',
          },
        ].map((button, i) => (
          <Button
            key={i}
            size="lg"
            w="100%"
            rounded="none"
            bg={tabIndex === button.state ? 'gray.300' : button.bgColor}
            fontSize={{ base: '1rem', md: '1.2rem' }}
            color="white"
            letterSpacing="wider"
            onClick={button.onClick}
          >
            {button.text}
          </Button>
        ))}
      </SimpleGrid>
      {tabIndex === 0 && <PickUp />}

      {tabIndex === 1 && <DropOff />}

      {tabIndex === 'hourly-rental' && <HourlyRental />}

      {tabIndex === 'tourist-spot' && <TouristSpot />}
    </>
  );
};

export default Reservation;
