import React from 'react';
import {
  Button,
  Box,
  Divider,
  Center,
  Input,
  Heading,
  FormControl,
  FormLabel,
} from '@chakra-ui/react';

const Login = () => {
  return (
    <>
      <Center pt={10}>
        <Box p={10} w={400}>
          <Heading fontSize="1.2rem" textAlign="center">
            登入
          </Heading>
          <Divider pt={2} />
          <Box pt={3} px={10}>
            <FormControl>
              <FormLabel>信箱</FormLabel>
              <Input
                // value={email}
                type="email"
                placeholder="請輸入信箱"
                rounded="none"
                borderColor="#000000"
              />
            </FormControl>
            <FormControl pt={3}>
              <FormLabel>密碼</FormLabel>
              <Input
                // value={password}
                type="password"
                placeholder="請輸入密碼"
                rounded="none"
                borderColor="#000000"
              />
            </FormControl>
            <Button mt={4} w="100%" bg="#000000" color="white" rounded="none">
              登入
            </Button>
          </Box>
        </Box>
      </Center>
    </>
  );
};

export default Login;
