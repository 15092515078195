import React, { useEffect, useState } from 'react';
import { useSetState } from 'react-use';
import {
  Text,
  Button,
  SimpleGrid,
  Heading,
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Divider,
  Center,
  HStack,
} from '@chakra-ui/react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const passengerNotices = [
  {
    title: '單趟多點服務',
    content:
      '如沿途加點接送須於預約時告知，三公里內免費+1地點，如超過三公里或跨縣市加點服務，請電話聯繫客服人員。',
  },
  {
    title: '舉牌服務',
    content: '若需舉牌服務須加收舉牌費300元，並請於預約時告知舉牌內容。',
  },
  {
    title: '安全座椅',
    content:
      '如乘坐貴賓內，有6歲以下兒童，貴賓應於預約時主動告知。並勾選 安全座椅服務，需酌收$200/趟。因車型限制，每車最多限預約二張安全座椅 !',
  },
  {
    title: '預約訂車時間',
    content:
      '請於訂車日前7天前預約 ( 不含預約當日及乘車當日 )，以便於車輛調度。\n接送時間如於夜間 24:00 ~ 06:00 時段，則需加收夜間加成$ 100 元（依指定接送時間或班機表訂抵達時間為準）。',
  },
  {
    title: '訂車 如需 變更∕取消 的時間',
    content:
      '如欲變更或取消，須於原預約時間往前24小時前聯繫客服。24小時內將無法變更或取消； 24小時內變更或取消須視同已使用過本服務，服務費用無法退還。',
  },
  {
    title: '送機/點對點接送 待時費',
    content:
      '依照貴賓約定時間到達指定地點，貴賓或同行者如逾約定送機時間 30 分鐘以上且聯絡不到時，司機得逕自離開。若司機接獲貴賓通知並繼續等待，則貴賓應自約定時間第 31 分鐘起，支付每小時待時費200 元，不足 1 小時以 1 小時計算。',
  },
  {
    title: '接機 待時費',
    content:
      '接機時，若班機提前抵達，請聯繫客服專線，車行將儘速調派車輛前往；但服務時間仍以原網路公告之班機預計抵達時間為主，請耐心等候。若貴賓已確認班機延誤，請立即致電通知車行，以免貴賓之權益受損。接機等候時間為飛機實際到達時間或貴賓約定時間起 60分鐘內，若服務人員確認班機已抵達但又無法連絡到貴賓時，服務人員得於該班機實際到達時間起算起等待 60 分鐘後逕行離開；若服務人員接獲貴賓通知並繼續等待，則貴賓應自第61分鐘起，支付每小時待時費200 元，不足 1 小時以 1 小時計算。',
  },
  {
    title: '接送車種',
    content:
      '本公司提供五人座及九人座為接送車輛，並僅以乘員及行李可乘載範圍為限，並且不得指定車輛之種類、大小或廠牌。如當場發生需另行增加車輛之情形，所發生之費用須由貴賓自行負擔。為確保行車安全，車內空間僅限乘坐貴賓，所有行李(包含手提行李)皆必須放置後車廂，放置重要文件、護照、錢包..等貴重物品之小肩包，在不影響行車安全下可隨身攜帶，惟最終乘載量須以不妨害行車安全為前提，車行有最終決定派車權。',
  },
  {
    title: '同行人數',
    content:
      '車輛限定人數依照交通法規規定，如行李加上同行家人或友人未超過原預約車輛可乘載之範圍，皆可一同搭乘不加價。貴賓應於預約時告知車行正確之同行人數以及行李數量尺寸，\n◎現場若超出之人數及行李導致無法承載，須請貴賓自行處理，若須由車行協助調派車輛，將視當日車輛調派狀況，在仍有空車情況下盡力給予調度車輛之協助並加收該趟費用，惟車輛調派已滿，將無法受理臨時調派，敬請貴賓見諒。',
  },
];

const Title = ({ children }) => {
  return (
    <>
      <Box pt={5} px={{ base: 5, md: 20 }}>
        <Heading fontSize="2rem" textAlign="center">
          {children}
        </Heading>
        <Divider pt={3} borderColor="gray.300" />
      </Box>
    </>
  );
};

const Main = () => {
  const [state, setState] = useSetState({
    isOpenModal: false,
  });

  const navigate = useNavigate();

  // 公告
  const API_URL = process.env.REACT_APP_API_URL;

  const [bulletin, setBulletin] = useState('');

  const getBulletin = () => {
    axios({
      method: 'get',
      url: `${API_URL}/api/options`,
    })
      .then((res) => {
        console.log(res.data.data.home_bulletin);
        setBulletin(res.data.data.home_bulletin);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getBulletin();
  });

  useEffect(() => {
    setTimeout(() => {
      setState({
        isOpenModal: true,
      });
    }, 100);
  }, []);

  return (
    <>
      <Box p={8} />
      <Box
        bgImg="url(/images/roadtrip-home.jpg)"
        bgPos="right"
        bgSize="cover"
        bgRepeat="no-repeat"
        minH="70vh"
      ></Box>

      <Center pt={3}>
        <Button
          onClick={() => {
            navigate('/reservation');
          }}
          w={300}
          h={16}
          fontSize="1.3rem"
          rounded="full"
          bg="black"
          color="white"
        >
          預約車輛
        </Button>
      </Center>

      {/* <Title>公司簡介</Title> */}

      {/* <Heading pt={4} fontSize="1.2rem" color="gray" textAlign="center" letterSpacing="wider">車輛派遣商</Heading>
      <Text pt={3} px={{ base: 5, md: 28 }} textAlign="center">
        客車租賃（股）公司 <br />
        有限公司 <br />
      </Text> */}

      {/* <Heading pt={4} fontSize="1.2rem" color="gray" textAlign="center" letterSpacing="wider">平台服務商</Heading>
      <Text pt={3} px={{ base: 5, md: 28 }} textAlign="center">
        有限公司
      </Text> */}

      {/* <Box pt={5} />
      <Title>乘客須知</Title>
      <SimpleGrid pt={6} columns={[1, null, 3]} spacing={10} px={{ base: 5, md: 28 }}>
        {passengerNotices.map((notice, i) => (
          <Box key={i}>
            <Heading fontSize="1.4rem" color="gray" textAlign="center" letterSpacing="wider">
              {notice.title}
            </Heading>
            <Text pt={2}>{notice.content}</Text>
          </Box>
        ))}
      </SimpleGrid> */}

      <HStack pt={10} justify="center">
        {/* <Button
          bg="black"
          color="white"
          rounded="none"
          onClick={() => {
            navigate('/terms');
          }}
        >
          使用條款
        </Button> */}
        {/* <Button
          bg="black"
          color="white"
          rounded="none"
          onClick={() => {
            navigate('/privacy');
          }}
        >
          隱私權政策
        </Button> */}
      </HStack>

      <Box p={5} />
      <Modal
        isCentered
        closeOnOverlayClick={false}
        isOpen={state.isOpenModal}
        onClose={() =>
          setState({
            isOpenModal: false,
          })
        }
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>公告</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{bulletin}</ModalBody>

          <ModalFooter>
            <Button
              w="100%"
              rounded="none"
              onClick={() =>
                setState({
                  isOpenModal: false,
                })
              }
            >
              Ok
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Main;
