import React from 'react';
import { Flex, Heading } from '@chakra-ui/react';
import { colors } from '../colors';
import { useNavigate } from 'react-router-dom';

const Navbar = () => {
  const navigate = useNavigate();
  return (
    <>
      <Flex
        px={10}
        py={3}
        bg={colors.theme}
        pos="fixed"
        top="0"
        w="100%"
        boxShadow="0 3px 3px lightgray"
        zIndex={10}
      >
        <Heading
          color="white"
          cursor="pointer"
          onClick={() => {
            navigate('/');
          }}
        >
          橘子包車
        </Heading>
      </Flex>
    </>
  );
};

export default Navbar;
